import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function (props) {
  return (
    <SvgIcon viewBox="0 0 33 44" {...props}>
      {/* esling-disable-next-line */}
      <path d="M6.02246 15H26.0225" stroke="#313A56" strokeWidth="2" strokeLinecap="round" />
      <path d="M6.02246 22H26.0225" stroke="#313A56" strokeWidth="2" strokeLinecap="round" />
      <path d="M6.02246 29H26.0225" stroke="#313A56" strokeWidth="2" strokeLinecap="round" />
    </SvgIcon>
  );
}
