import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function (props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* esling-disable-next-line */}
      <g >
        <path
          fill='transparent'
          d="M22.286 11.1431H15.4289C15.2015 11.1431 14.9835 11.2334 14.8228 11.3942C14.662 11.5549 14.5717 11.773 14.5717 12.0003V22.286C14.5717 22.5133 14.662 22.7313 14.8228 22.8921C14.9835 23.0528 15.2015 23.1431 15.4289 23.1431H22.286C22.5133 23.1431 22.7313 23.0528 22.8921 22.8921C23.0528 22.7313 23.1431 22.5133 23.1431 22.286V12.0003C23.1431 11.773 23.0528 11.5549 22.8921 11.3942C22.7313 11.2334 22.5133 11.1431 22.286 11.1431ZM22.286 0.857422H15.4289C15.2015 0.857422 14.9835 0.947728 14.8228 1.10847C14.662 1.26922 14.5717 1.48724 14.5717 1.71456V5.16028C14.5717 5.38761 14.662 5.60562 14.8228 5.76637C14.9835 5.92712 15.2015 6.01742 15.4289 6.01742H22.286C22.5133 6.01742 22.7313 5.92712 22.8921 5.76637C23.0528 5.60562 23.1431 5.38761 23.1431 5.16028V1.71456C23.1431 1.48724 23.0528 1.26922 22.8921 1.10847C22.7313 0.947728 22.5133 0.857422 22.286 0.857422ZM8.57171 0.857422H1.71456C1.48724 0.857422 1.26922 0.947728 1.10847 1.10847C0.947728 1.26922 0.857422 1.48724 0.857422 1.71456V12.0003C0.857422 12.2276 0.947728 12.4456 1.10847 12.6064C1.26922 12.7671 1.48724 12.8574 1.71456 12.8574H8.57171C8.79904 12.8574 9.01705 12.7671 9.1778 12.6064C9.33854 12.4456 9.42885 12.2276 9.42885 12.0003V1.71456C9.42885 1.48724 9.33854 1.26922 9.1778 1.10847C9.01705 0.947728 8.79904 0.857422 8.57171 0.857422ZM8.57171 17.9831H1.71456C1.48724 17.9831 1.26922 18.0734 1.10847 18.2342C0.947728 18.3949 0.857422 18.613 0.857422 18.8403V22.286C0.857422 22.5133 0.947728 22.7313 1.10847 22.8921C1.26922 23.0528 1.48724 23.1431 1.71456 23.1431H8.57171C8.79904 23.1431 9.01705 23.0528 9.1778 22.8921C9.33854 22.7313 9.42885 22.5133 9.42885 22.286V18.8403C9.42885 18.613 9.33854 18.3949 9.1778 18.2342C9.01705 18.0734 8.79904 17.9831 8.57171 17.9831Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_5526_3419">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}
