import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

export default function (props) {
  return (
    <SvgIcon xmlns="http://www.w3.org/2000/svg" {...props}>
      {/* esling-disable-next-line */}
      <path
        d="M12.5658 4.30872L13.0608 2.95072C13.193 2.59012 13.463 2.29673 13.8113 2.13493C14.1596 1.97314 14.5579 1.95616 14.9188 2.08772C15.0974 2.15217 15.2614 2.25122 15.4017 2.3792C15.5419 2.50717 15.6555 2.66156 15.7359 2.83351C15.8164 3.00546 15.8621 3.19159 15.8705 3.38124C15.8789 3.57089 15.8497 3.76034 15.7848 3.93872L15.1248 5.74872C15.2568 5.38794 15.5267 5.09432 15.875 4.93232C16.2234 4.77033 16.6218 4.75321 16.9828 4.88472C17.1614 4.94917 17.3254 5.04822 17.4657 5.1762C17.6059 5.30417 17.7195 5.45856 17.7999 5.63051C17.8804 5.80246 17.9261 5.98859 17.9345 6.17824C17.9429 6.36789 17.9137 6.55734 17.8488 6.73572L17.1878 8.54572C17.32 8.18512 17.59 7.89173 17.9383 7.72993C18.2866 7.56814 18.6849 7.55116 19.0458 7.68272C19.2245 7.74716 19.3887 7.84625 19.529 7.9743C19.6693 8.10234 19.7829 8.25683 19.8634 8.42889C19.9438 8.60096 19.9895 8.78721 19.9978 8.97698C20.0061 9.16674 19.9769 9.35628 19.9118 9.53472L17.8738 15.1157C17.1548 17.0827 16.7958 18.0657 16.2098 18.7397C15.8688 19.1307 15.1968 19.5437 14.6768 19.8287C14.2229 20.0687 13.8714 20.4652 13.6878 20.9447L13.3028 21.9987M12.5658 4.30872C12.6309 4.13021 12.6601 3.94062 12.6517 3.75081C12.6433 3.56099 12.5975 3.37471 12.517 3.20263C12.4364 3.03056 12.3227 2.87609 12.1823 2.74808C12.0419 2.62008 11.8776 2.52106 11.6988 2.45672C11.3378 2.32521 10.9394 2.34233 10.591 2.50432C10.2427 2.66632 9.97285 2.95994 9.84079 3.32072L7.04379 10.9787L6.10979 8.97372C6.01261 8.7661 5.87274 8.58132 5.6993 8.43143C5.52585 8.28154 5.32275 8.16993 5.10324 8.10387C4.88373 8.03781 4.65276 8.01879 4.4254 8.04806C4.19804 8.07732 3.97942 8.15422 3.78379 8.27372C3.47482 8.46139 3.237 8.74646 3.10774 9.08407C2.97849 9.42168 2.96514 9.79269 3.06979 10.1387L4.81879 15.4857C5.15379 16.5087 5.04479 17.8597 4.67579 18.8707M12.5658 4.30872L10.9128 8.83272M15.1238 5.74872L13.3068 10.7267M15.6998 12.6187L17.1878 8.54672M21.8298 12.9987C22.3768 14.6337 21.5568 16.4247 19.9998 16.9987"
        stroke="currentColor"
        fill='transparent'
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
